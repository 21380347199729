import { PaymentConfigMethodOverrideDto } from "apps/public-portal/src/app/core/api/PaymentIntegrationApiClients";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ConvenienceFeeConfigModel {
    /** Whether or not we display information about the convenience fee */
    enabled: boolean;
    /** An optional label to display instead of "Convenience Fee" to express an additional fee charged */
    label?: string;
    /** Indicative of what format the rate is given in: either "percent" or "flat" */
    format?: string;
    /** A value representing the fee in the specified format */
    rate?: number;
    /** A note about the fee to be displayed in a tooltip next to the fee */
    note?: string;
    /** The max amount of time (in minutes) that the frontend should allow for a third party session before the lambda will jump in */
    processorExpiryTime?: number;

    fromDto(dto: PaymentConfigMethodOverrideDto) {
        this.enabled = dto.convenienceFeeEnabled;
        this.label = dto.convenienceFeeLabel;
        this.format = dto.convenienceFeeFormat;
        this.rate = dto.convenienceFeeRate;
        this.note = dto.convenienceFeeNote;
        this.processorExpiryTime = dto.processorExpiryTime;
    }
}
